import React, {Component} from 'react';
import {
    Container,
    Title,
    InputBox,
    Label,
    Input,
    Button,
    InputError, ShowTicketContainer
} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {convertToEnglishNumbers, deleteNullItemInArray, isNumber, parseErrorMessage, toDate} from "../../../helpers";
import AccountService from "../../../services/AccountService";
import * as Yup from "yup";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import {BlockText, FormTitle} from "../../../scenes/Register/style";
import {NavLink} from "react-router-dom";
import {ROUTE_LOGIN} from "../../../routes/routes";
import {Formik} from "formik";
import SearchService from "../../../services/SearchService";
import Ticket from "../Ticket/Ticket";
import ShowTicket from "../Ticket/ShowTicket";

class CheckMyTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            submitting: false,
            ticket: null
        }
    }

    onKeyUp = (e, setFieldValue) => {
        let value = e.target.value.replaceAll(" ", "");
        let out = [];
        out.push(value.substr(0, 4));
        if(value.length > 4)
            out.push(value.substr(4, 3));
        if(value.length > 7)
            out.push(value.substr(7, 3));
        setFieldValue('code', out.join(' '));
    }

    handleSearch = async (values, actions) => {
        const {applicationContext, alert, ticketCreatingContext} = this.props;
        this.setState({ submitting: true })
        SearchService.searchByTicketId(values.code).then((result) => {
            if(result){
                this.setState({
                    ticket: result
                })
            }
        }).catch((e) => {
            if(e.response.data.hasOwnProperty('errors') && Object.keys(e.response.data.errors).length > 0){
                Object.keys(e.response.data.errors).map((field) => {
                    actions.setFieldError(field, applicationContext.translator(e.response.data.errors[field][0]))
                })
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(e)));
            }

        }).finally(() => {
            this.setState({ submitting: false })
        })
    }
    onKeyPress = (e) => {
        if(!isNumber(e))
            e.preventDefault();
    }
    render() {
        const {date, applicationContext} = this.props;
        return (
            <Container showResult={this.state.ticket !== null}>
                <Formik
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={{
                        code: this.state.code
                    }}
                    validationSchema={Yup.object().shape({
                        code: Yup.string()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('len', applicationContext.translator("The ticket number must be 10 digits"), (value) => value.replaceAll(" ", "").length === 10)
                            .label(applicationContext.translator("Ticket Id"))
                    })}
                    onSubmit={this.handleSearch}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) =>
                        (
                            <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                {this.state.ticket?(
                                    this.state.ticket.draw?(
                                        <Title>{applicationContext.translator("Ticket used for lottery on date ${date}").replaceAll("${date}", toDate(this.state.ticket.draw.date, applicationContext.state.locale))}</Title>
                                    ):(
                                        <Title>{applicationContext.translator("Ticket purchased for lottery on date ${date}").replaceAll("${date}", toDate(this.state.ticket.draw_date, applicationContext.state.locale))}</Title>
                                    )
                                ):(
                                    <Title>{applicationContext.translator("Check my ticket")}<br/>{applicationContext.translator("For all time draw")}</Title>
                                )}
                                {this.state.ticket?(
                                    <ShowTicketContainer>
                                        <ShowTicket ticket={this.state.ticket} prize={this.state.ticket.prize} />
                                    </ShowTicketContainer>

                                ):(
                                    <React.Fragment>
                                        <InputBox>
                                            <Input type={"text"}
                                                   name={"code"}
                                                   maxLength={12}
                                                   onChange={(e) => {
                                                       e.target.value = convertToEnglishNumbers(e.target.value);
                                                       handleChange(e);
                                                   }}
                                                   onKeyUp={(e) => {
                                                       this.onKeyUp(e, setFieldValue);
                                                   }}
                                                   onKeyPress={this.onKeyPress}
                                                   value={values.code}
                                                   save={"off"}
                                                   placeholder={applicationContext.translator("Your ticket number")}
                                                   isInvalid={touched && touched.code && errors.code}
                                                   errors={errors}
                                                   touched={touched}
                                            />
                                            <InputError hidden={errors.code === undefined && true}>{errors.code}</InputError>
                                        </InputBox>

                                        <Button type={"submit"} disabled={this.state.submitting}>
                                            {this.state.submitting?<Spinner animation="border" variant="light" size={"sm"} />:applicationContext.translator("Search")}
                                        </Button>
                                    </React.Fragment>
                                )}
                            </Form>
                        )}
                </Formik>


            </Container>
        );
    }
}
CheckMyTicket.propTypes = {
    date: PropTypes.any
}
export default withApplicationContext(CheckMyTicket);