import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {
    TicketSection,
    TicketsContainer,
    AddNewTicket,
    AddNewTicketWrapper,
    AddNewTicketButton,
    Title
} from "./style.jsx";
import {Col, Container, Row} from "react-bootstrap";
import {TicketCreatingProvider} from "../../contexts/TicketCreatingContext";
import Ticket from "../../components/ui/Ticket/Ticket";
import Button from "../../components/ui/Button";
import {nullArray, shuffle, zeroPad} from "../../helpers";
import Checkout from "./Checkout";
import Loading from "../../components/ui/Loading";
import SuccessMessage from "../../components/ui/SuccessMessage/SuccessMessage";
import withRouter from "../../contexts/withRouter";
import {ROUTE_ACCOUNT_MY_TICKETS} from "../../routes/routes";
import CommonService from "../../services/CommonService";
class TicketCreating extends Component {
    constructor(props) {
        super(props);
        let tickets = [];
        tickets.push({numbers: shuffle(), id: "ticket0", error: null})

        this.state = {
            tickets: tickets,
            deleting: [],
            draw: 1,
            openNumberChooser: false,
            loading: true,
            successfully: false,
            lock: false,
            lock_message: null,
            server_time: null
        }

    }
    componentDidMount = async () => {
        const {applicationContext} = this.props;
        let common = await CommonService.fetchCommonData();
        this.setState({
            loading: false,
            lock: common['lock_time'],
            lock_start_hour: common['lock_start_hour'],
            lock_end_hour: common['lock_end_hour'],
            lock_message: applicationContext.translator("Ticket sales are closed from ${start}:00 to ${end}:00 due to the start of the lottery").toString()
                .replaceAll("${start}", zeroPad(common['lock_start_hour'], 2))
                .replaceAll("${end}", zeroPad(common['lock_end_hour'], 2)),
            server_time: common['tehran_time']
        })
    }

    onNumberChooser = (e, id = null) => {
        this.setState({
            openNumberChooser: (this.state.openNumberChooser !== id)?id:null
        })
    }

    onAddNewTicket = (e) => {
        let tickets = this.state.tickets;
        tickets.push({numbers: nullArray(), id: "ticket"+ (tickets.length + 1)})
        this.setState({
            tickets
        })
    }
    handleUpdateNumbersOfTicket = (id, numbers, close = true) => {
        let tickets = this.state.tickets;
        tickets.map((ticket) => {
            if(ticket.id === id) {
                ticket.numbers = numbers;
                ticket.error = null;
            }
        })
        let state = {
            tickets
        };
        if(close)
            state.openNumberChooser = (this.state.openNumberChooser === id)?null:this.state.openNumberChooser;
        this.setState(state)
    }
    handleDeleteTicket = (id) => {
        let deleting = this.state.deleting
        deleting.push(id);
        this.setState({
            deleting
        }, () => {
            setTimeout(() => {
                let tickets = this.state.tickets;
                tickets.map((ticket, index) => {
                    if(ticket.id === id)
                        tickets.splice(index, 1)
                });
                this.setState({
                    tickets,
                    deleting: []
                })
            }, 300)

        })

    }
    handleUpdateDraw = (draw) => {
        this.setState({
            draw
        })
    }
    handleUpdateTickets = (tickets) => {
        this.setState({
            tickets
        })
    }
    handleUpdateSuccess = (status) => {
        let tickets = [];
        tickets.push({numbers: shuffle(), id: "ticket0", error: null})
        this.setState({
            successfully: status,
            tickets,
            draw: 1
        })
    }
    doGotoMyTickets = (e) => {
        const {navigate} = this.props;
        navigate(ROUTE_ACCOUNT_MY_TICKETS);
    }
    handleLockSelling = async (lock, start, end, message) => {
        this.setState({
            lock: lock,
            lock_start_hour: start,
            lock_end_hour: end,
            lock_message: message
        })
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <TicketCreatingProvider value={{
                state: this.state,
                onNumberChooser: this.onNumberChooser,
                handleUpdateNumbersOfTicket: this.handleUpdateNumbersOfTicket,
                handleDeleteTicket: this.handleDeleteTicket,
                handleUpdateDraw: this.handleUpdateDraw,
                handleUpdateTickets: this.handleUpdateTickets,
                handleUpdateSuccess: this.handleUpdateSuccess,
                handleLockSelling: this.handleLockSelling
            }}>
                <Container style={{ paddingTop: '6em' }}>
                    {this.state.loading?(
                        <Loading animation={true} transparent={true} />
                    ):(
                        this.state.successfully?(
                            <SuccessMessage
                                message={"Your purchase was successful."}
                                subText={"You can see the purchased tickets by referring to my tickets section."}
                                icon={true}
                            >
                                <Button color={"primary"} margin={"0 .2em"} onClick={this.doGotoMyTickets}>{applicationContext.translator("My Tickets")}</Button>
                                <Button color={"primary"} margin={"0 .2em"} onClick={() => this.setState({ successfully: false })}>{applicationContext.translator("Buy Tickets")}</Button>
                            </SuccessMessage>
                        ):(
                            !this.state.lock?(
                            <React.Fragment>
                                <Title>{applicationContext.translator("With how many lottery tickets do you want to play?")}</Title>
                                <Row>
                                    <Col lg={9}>
                                        <TicketsContainer>
                                            {this.state.tickets.map((ticket, index) => {
                                                return (
                                                    <TicketSection key={index}>
                                                        <Ticket index={index + 1} editable={true} error={ticket.error} numbers={ticket.numbers} id={ticket.id} deleting={this.state.deleting.includes(ticket.id) && "delete"}/>
                                                    </TicketSection>
                                                )
                                            })}
                                            <TicketSection>
                                                <AddNewTicket>
                                                    <AddNewTicketWrapper>
                                                        <div>{applicationContext.translator("Have an extra chance to win by buying new card")}</div>
                                                        <Button color={"primary"} onClick={this.onAddNewTicket}>{applicationContext.translator("Add new ticket")}</Button>
                                                    </AddNewTicketWrapper>
                                                </AddNewTicket>
                                            </TicketSection>

                                        </TicketsContainer>
                                    </Col>
                                    <Col lg={3}>
                                        <Checkout />
                                    </Col>
                                </Row>
                            </React.Fragment>):(
                                <React.Fragment>
                                    <SuccessMessage icon={true}
                                                    iconName={"clock"}
                                                    color={"#f44336"}
                                                    message={this.state.lock_message}>
                                    </SuccessMessage>
                                </React.Fragment>
                            )
                        )
                    )}

                </Container>


            </TicketCreatingProvider>


        );
    }
}

export default withApplicationContext(withRouter(TicketCreating));