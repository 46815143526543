import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import withAuth from "../../../contexts/withAuth";
import LoggedIn from "../LoggedIn";
import * as Yup from "yup";
import {Col, Form, Row} from "react-bootstrap";
import {DrawResult, DrawResultItem, Number, Numbers} from "./style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import Card from "../../../components/ui/Card";
import {convertToDate, numberWithCommas, ticketId, toDate, toSearchDataList} from "../../../helpers";
import SearchDropdown from "../../../components/ui/SearchDropdown/SearchDropdown";
import Dropdown from "../../../components/ui/Dropdown";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AdminService from "../../../services/AdminService";
import AccountService from "../../../services/AccountService";
import SuccessMessage from "../../../components/ui/SuccessMessage";
import {Select} from "../../../components/ui/SearchDropdown/style";
import {TicketSection} from "../../MyAccount/MyTickets/style";
import Ticket from "../../../components/ui/Ticket/Ticket";
import {Ball, BallContainer} from "../../../components/ui/Ticket/style";
import TicketChooser from "../../../components/ui/TicketChooser";

class EnterWinningNumber extends Component {
    refNumber = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            loading: true,
            loadingDraw: false,
            success: false,
            draws: [],
            drawsData: [],
            rounds: [],
            drawResult: null,
            winners: [],
            form: {
                draw_id: "",
                number: "",
                round: ""
            }
        }

    }
    componentDidMount = async () => {
        await this.fetchDraws();

    }
    fetchDraws = async () => {
        this.setState({ loading: true });
        await AdminService.draws({
            sort_field: 'date',
            sort_dir: 'asc',
            limit: 10,
            status: 'pending'
        }).then((draws) => {
            let _list = [];
            let draw_id = "";
            draws.data.forEach(function (obj){
                let persianDate = convertToDate(obj['date'],  "fa", 'YYYY-MM-DD', 'dddd, D MMMM YYYY');
                _list.push({
                    labelKey: obj['id'],
                    value: obj['date'] + " ("+persianDate+")" + " #" + obj['num_of_draw'],
                    isSelected: false
                })

            });
            if(_list.length > 0) {
                _list[_list.length - 1].isSelected = true
                draw_id = _list[_list.length - 1].labelKey;
            }
            this.setState({
                draws: _list,
                drawsData: draws,
                form: {
                    ...this.state.form,
                    draw_id
                },
                loading: false
            })
        })
    }
    onSubmit = async (values, number) => {
        const {applicationContext, alert} = this.props;

        this.setState({ submitting: true });
        let data = {
            draw_id: values.draw_id,
            round: values.round,
            number: number
        }
        await AdminService.draw(data).then((data) => {
            this.setState({
                drawResult: data,
                success: data.winners.length > 0
            })
        }).catch((e) => {
            alert.error(applicationContext.translator(e.response.data.message));

        }).finally(() => {
            this.setState({ submitting: false });
        });
    }
    handleCheckWinner = (number) => {
        let fill = false;
        let find = this.state.drawResult.winners.filter((w) => w.numbers.includes(number));
        return find.length > 0;
    }
    render() {
        const {applicationContext, adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <Formik
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={this.state.form}
                    validationSchema={Yup.object().shape({
                        draw_id: Yup.number().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Draw")),
                        round: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .label(applicationContext.translator("Round")),
                        number: Yup.number()
                            .required(applicationContext.translator("You must enter your ${path}"))
                            .test('number', applicationContext.translator("${path} must be digit between 1 to 75"), (value) => value > 0 && value <= 75)
                            .label(applicationContext.translator("Number")),

                    })}
                    onSubmit={this.onSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) =>
                        (
                            <Card maxWidth={800} center={true} top={"2em"}>
                                <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                    {!this.state.success && <SearchDropdown
                                        name={"draw_id"}
                                        onChange={(e) => {
                                            e.selectedKey[0] !== undefined && setFieldValue('state_id', e.selectedKey[0]);
                                            let drawId = e.selectedKey[0];
                                            this.setState({
                                                drawLoading: true
                                            }, () => {
                                                let draw = this.state.drawsData.data.filter((r) => r.id === drawId)
                                                if(draw.length > 0){
                                                    draw = draw[0];
                                                    let rounds = [];
                                                    if(!draw['round_1_completed_at'])
                                                        rounds.push(1);
                                                    if(!draw['round_2_completed_at'])
                                                        rounds.push(2);
                                                    if(!draw['round_3_completed_at'])
                                                        rounds.push(3);
                                                    this.setState({
                                                        rounds,
                                                        drawLoading: false
                                                    })
                                                }
                                            })
                                        }}
                                        data={this.state.draws}
                                        value={values.draw_id}
                                        errors={errors}
                                        touched={touched}
                                    />}
                                    {!this.state.drawLoading && <React.Fragment>
                                        {!this.state.success && <Dropdown name={"round"}
                                                                          value={values.round}
                                                                          onChange={handleChange}
                                                                          errors={errors}
                                                                          touched={touched}
                                        >
                                            <option value={""}>{applicationContext.translator("Please choose...")}</option>
                                            {this.state.rounds.map((round) => <option value={round}>{applicationContext.translator("Round")} {round}</option>)}
                                        </Dropdown>}
                                        {!this.state.success && values.round && <TicketChooser selected={this.state.drawResult?this.state.drawResult.numbers:[]} onClick={(number) => this.onSubmit(values, number)}/>}


                                        {this.state.drawResult &&
                                            <React.Fragment>
                                                <DrawResult>
                                                    {!this.state.success && this.state.drawResult.top.map((top) => {
                                                        return (
                                                            <DrawResultItem>
                                                                <b>{ticketId(top['ticket_id'])}</b>
                                                                <span>{top['cnt']}</span>
                                                            </DrawResultItem>
                                                        )
                                                    })}
                                                    {this.state.success && this.state.drawResult.winners.map((ticket) => {
                                                        return (
                                                            <React.Fragment>
                                                                <DrawResultItem bingo={true}>
                                                                    <b>{ticketId(ticket['id'])}</b>
                                                                    <span>BINGO</span>
                                                                </DrawResultItem>
                                                                <TicketChooser highlight={ticket.numbers} />
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </DrawResult>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>}



                                </Form>
                            </Card>
                        )}
                </Formik>
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(EnterWinningNumber)))));