import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const TicketGroup = styled.div`
  padding: 3em 1em 0em 1em;
  position: relative;
  margin-bottom: 1em;
  border-radius: 10px;
  ${(props) => props.color === "light" && `
    & .draw-row-date{
        background: #f1f1f1;
        color: #333;
    }
  
  `}
`
export const TicketGroupNumbers = styled.div`
  width: 100%;
  padding: 2em 0 0 0;
`
export const TicketGroupDate = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: #4e4e4e;
  border-radius: 10px;
  padding: 0.5em 1em;
  font-size: 1.1em;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 53px;
`
export const TicketDraws = styled.div`
  padding: 1em;
`
export const TicketGroupContainer = styled.div`
  padding: 2em 0 1em 0;
  display: flex;
  flex-wrap: wrap;
`;

export const TicketSection = styled.div`
  transition: all .3s;
  width: calc(25% - 2em);
  margin: 0 1em;
  position: relative;
  @media screen and (max-width: 1400px) {
    width: calc(50% - 4em);
  }
  @media screen and (max-width: 992px) {
    width: calc(100% - 2em);
  }
`;


export const Plus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: 600;
  margin: 0 0.3em;
`