import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {
    SectionContainer,
    DrawResultTitle, ResultContainer,
    Title
} from "./style.jsx";
import {Container, Row, Col, NavLink} from 'react-bootstrap';
import HorizontalBalls from "../../components/ui/Ball/HorizontalBalls";
import JackpotPrice from "../../components/ui/JackpotPrice/JackpotPrice";
import NationalIDBox from "../../components/ui/NationalIDBox";
import Winner from "../../components/ui/Winner";
import PersianDate from "../../components/ui/PersianDate";
import {Ball, BallContainer} from "../../components/ui/Ticket/style";
import ShowTicket from "../../components/ui/Ticket/ShowTicket";

class DrawResults extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            applicationContext.state.lastLottery?<SectionContainer>
                <Container>
                    <DrawResultTitle>
                        <span>{applicationContext.translator("Draw Result")}</span>
                        {applicationContext.state.lastLottery && <PersianDate date={applicationContext.state.lastLottery.date}/>}
                    </DrawResultTitle>
                    <Row>
                        <Col md={4}>
                            <ResultContainer>
                                <Title>{applicationContext.translator("Round 1 winner")}</Title>
                                <ShowTicket ticket={applicationContext.state.lastLottery.round_1_winner}
                                            prize={applicationContext.state.lastLottery.round_1_winner.prize}
                                            dateOff={true}
                                />
                            </ResultContainer>
                        </Col>
                        <Col md={4}>
                            <ResultContainer>
                                <Title>{applicationContext.translator("Round 2 winner")}</Title>
                                <ShowTicket ticket={applicationContext.state.lastLottery.round_2_winner}
                                            prize={applicationContext.state.lastLottery.round_2_winner.prize}
                                            dateOff={true}
                                />
                            </ResultContainer>
                        </Col>
                        <Col md={4}>
                            <ResultContainer>
                                <Title>{applicationContext.translator("Round 3 winner")}</Title>
                                <ShowTicket ticket={applicationContext.state.lastLottery.round_3_winner}
                                            prize={applicationContext.state.lastLottery.round_3_winner.prize}
                                            dateOff={true}
                                />
                            </ResultContainer>
                        </Col>
                    </Row>

                </Container>
            </SectionContainer>:null

        );
    }
}

export default withApplicationContext(DrawResults);