import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {withAccountContext} from "../../contexts/AccountContext";
import SubHeaderBar from "../../components/ui/SubHeaderBar/SubHeaderBar";
import Loading from "../../components/ui/Loading";
import SearchService from "../../services/SearchService";
import {
    Plus,
    TicketGroup,
    TicketGroupDate,
    TicketDraws
} from "../MyAccount/PlayedHistory/style";
import {currentMonth, currentYear, toDate, yearRange, yearToNowRange} from "../../helpers";
import {Ball, BallContainer} from "../../components/ui/Ticket/style";
import {SearchBar, SearchItem} from "./style";
import {Container} from "react-bootstrap";
import Dropdown from "../../components/ui/Dropdown/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "../../components/ui/Button";
import TicketChooser from "../../components/ui/TicketChooser";
class DrawResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            searching: false,
            data: [],
            year: currentYear(props.applicationContext.state.locale),
            month: currentMonth(props.applicationContext.state.locale)
        }

    }


    componentDidMount = async () => {
        await this.fetchDraws();
    }

    fetchDraws = async () => {
        const {applicationContext} = this.props;
        this.setState({ searching: true });
        SearchService.drawsResults({
            month: this.state.month,
            year: this.state.year,
            locale: applicationContext.state.locale
        }).then((data) => {
            this.setState({
                data: data.data,
                loading: false
            })
        }).finally(() => {
            this.setState({ searching: false });
        })
    }

    onSearch = async (e) => {
        await this.fetchDraws();
    }

    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <SubHeaderBar />
                {this.state.loading?(
                    <Loading transparent={true} animation={true} />
                ):(
                    <Container style={{ paddingTop: '6em' }}>
                        {this.state.data.map((draw) => {
                            return (
                                <TicketGroup color={"light"}>
                                    <TicketGroupDate className={"draw-row-date"}>
                                        <div>{applicationContext.translator("Draw results at ${date}").replaceAll("${date}", toDate(draw.date, applicationContext.state.locale))}</div>

                                    </TicketGroupDate>
                                    <TicketDraws>
                                        <TicketChooser disabled={true} highlight={draw.round_1_winner.numbers} title={toDate(draw.date, applicationContext.state.locale)+" - "+applicationContext.translator("Round") + " 1"}/>
                                        <TicketChooser disabled={true} highlight={draw.round_2_winner.numbers} title={toDate(draw.date, applicationContext.state.locale)+" - "+applicationContext.translator("Round") + " 2"}/>
                                        <TicketChooser disabled={true} highlight={draw.round_3_winner.numbers} title={toDate(draw.date, applicationContext.state.locale)+" - "+applicationContext.translator("Round") + " 3"}/>
                                    </TicketDraws>
                                </TicketGroup>
                            )
                        })}
                        {applicationContext.state.firstLottery && <SearchBar>
                            <SearchItem>
                                <Dropdown
                                    name={"year"}
                                    onChange={(e) => this.setState({ year: e.target.value })}
                                    value={this.state.year}
                                    margin={"0"}
                                >
                                    {yearToNowRange(applicationContext.state.firstLottery, applicationContext.state.locale).map((year, index) => {
                                        return <option key={index} value={year} >{year}</option>
                                    })}
                                </Dropdown>
                            </SearchItem>
                            <SearchItem>
                                <Dropdown
                                    name={"month"}
                                    onChange={(e) => this.setState({ month: e.target.value })}
                                    value={this.state.month}
                                    margin={"0"}
                                >
                                    <option value={1} >{applicationContext.translator("January")}</option>
                                    <option value={2} >{applicationContext.translator("February")}</option>
                                    <option value={3} >{applicationContext.translator("March")}</option>
                                    <option value={4} >{applicationContext.translator("April")}</option>
                                    <option value={5} >{applicationContext.translator("May")}</option>
                                    <option value={6} >{applicationContext.translator("June")}</option>
                                    <option value={7} >{applicationContext.translator("July")}</option>
                                    <option value={8} >{applicationContext.translator("August")}</option>
                                    <option value={9} >{applicationContext.translator("September")}</option>
                                    <option value={10} >{applicationContext.translator("October")}</option>
                                    <option value={11} >{applicationContext.translator("November")}</option>
                                    <option value={12} >{applicationContext.translator("December")}</option>
                                </Dropdown>
                            </SearchItem>
                            <SearchItem>
                                <Button color={"primary"} disabled={this.state.searching} submitting={this.state.searching} size={3} margin={"0 .6em"} onClick={this.onSearch} radius={10}>{applicationContext.translator("Search")}</Button>
                            </SearchItem>

                        </SearchBar>}
                    </Container>
                )}

            </React.Fragment>

        );
    }
}

export default withApplicationContext(withAccountContext(DrawResults));