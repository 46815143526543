import React, {Component} from 'react';
import {
    Container, Wrapper, Body, Toolbar,
    IconButton, Icon, NameContainer, Name,
    Price, BallContainer, Ball, ChooseNumberButton,
    NumbersListContainer, NumbersListToolbar, ToolbarButton,
    ToolbarButtonWrapper, NumbersList, ContainerWrapper, TicketID, TicketDate,
    ErrorText
} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {addNumberToArray, arrayRange, nullArray, shuffle, ticketId} from "../../../helpers";
import {withTicketCreatingContext} from "../../../contexts/TicketCreatingContext";
class Ticket extends Component {
    constructor(props) {
        super(props);
        let numbers = (props.numbers)?props.numbers:nullArray();
        for(let i = 0 ; i < (6 - numbers.length); i++){
            numbers.push(null);
        }
        this.state = {
            numbers: numbers,
            numbersTemp: [],
            delete: false,
            deleting: false,
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {ticketCreatingContext, id} = prevProps;
        if(ticketCreatingContext){
            if(ticketCreatingContext.state.openNumberChooser === id && this.props.ticketCreatingContext.state.openNumberChooser !== id && this.state.numbersTemp.length > 0){
                this.setState({
                    numbersTemp: [],
                    numbers: this.state.numbersTemp
                })
            }
        }

    }

    componentDidMount = () => {
    }
    onHandleClickOnBalls = (e) => {
        const {id, ticketCreatingContext} = this.props;
        if(this.state.numbersTemp.length === 0){
            this.setState({
                numbersTemp: this.state.numbers,
                numbers: nullArray()
            }, () => {
                ticketCreatingContext.onNumberChooser(e, id)
            })
        }else{
            this.setState({
                numbersTemp: [],
                numbers: this.state.numbersTemp
            }, () => {
                ticketCreatingContext.onNumberChooser(e)
            })
        }
    }
    onChooseNumber = (number) => {
        const {id, ticketCreatingContext} = this.props;
        if(this.state.numbers.includes(null) && !this.state.numbers.includes(number)){
            let numbers = addNumberToArray(this.state.numbers, number);

            this.setState({
                numbers
            }, () => {
                if(!this.state.numbers.includes(null)){
                    this.setState({
                        numbersTemp: []
                    }, () => {
                        ticketCreatingContext.handleUpdateNumbersOfTicket(id, numbers);
                    })
                }
            })
        }
    }
    doShuffle = (e) => {
        const {id, ticketCreatingContext} = this.props;
        let numbers = shuffle();
        this.setState({
            numbers
        }, () => {
            ticketCreatingContext.handleUpdateNumbersOfTicket(id, numbers);

        })

    }
    doClearNumbers = (e) => {
        const {id, ticketCreatingContext} = this.props;
        this.setState({
            numbers: nullArray()
        });
    }
    doShuffleAnimation = (e) => {
        const {id, ticketCreatingContext} = this.props;
        for(let i = 1; i <= 10 ; i++){
            setTimeout(() => {
                let numbers = shuffle();
                this.setState({
                    numbers
                }, () => {
                    if(i === 10){
                        ticketCreatingContext.handleUpdateNumbersOfTicket(id, numbers, false);
                    }
                })
            }, (80 * i))
        }
    }
    doDelete = (e) => {
        const {id, ticketCreatingContext} = this.props;
        ticketCreatingContext.handleDeleteTicket(id)

    }
    onHandleClickOnChooseButton = (e) => {
        const {id, ticketCreatingContext} = this.props;
        if(ticketCreatingContext.state.openNumberChooser !== id){
            let numbersTemp = this.state.numbers;
            let numbers = (!this.state.numbers.includes(null))?nullArray():this.state.numbers;
            this.setState({
                numbers,
                numbersTemp
            }, () => {
                ticketCreatingContext.onNumberChooser(e, id);
            })

        }else{
            let numbers = (this.state.numbers.includes(null))?this.state.numbersTemp:this.state.numbers;
            this.setState({
                numbers,
                numbersTemp: []
            }, () => {
                ticketCreatingContext.handleUpdateNumbersOfTicket(id, numbers, true);
            })

        }

    }
    render() {
        const {applicationContext, index, id, deleting, prize, selected, ticketCreatingContext, editable, price, date, error, reserve, dateOff} = this.props;
        return (
            <Container error={error} className={deleting && "delete"}>
                <Wrapper>
                    <Body>
                        <NameContainer>
                            {prize?(
                                <Name small={true}>{applicationContext.translator(prize)}</Name>
                            ):(
                                <React.Fragment>
                                    <Name>{applicationContext.translator("Lot ")} {index}</Name>
                                    <Price>${price??applicationContext.state.ticketPrice}</Price>
                                </React.Fragment>
                            )}

                        </NameContainer>
                        <BallContainer>
                            <Ball bingo={true}><span>B</span></Ball>
                            <Ball bingo={true}><span>I</span></Ball>
                            <Ball bingo={true}><span>N</span></Ball>
                            <Ball bingo={true}><span>G</span></Ball>
                            <Ball bingo={true} rightLast={true}><span>O</span></Ball>
                            {this.state.numbers.map((number, i) => {
                                return (
                                    <React.Fragment>
                                        {i === 12 &&
                                            <Ball bingo={true}>
                                                <span>BINGO</span>
                                            </Ball>
                                        }
                                        <Ball editable={editable}
                                              fill={true}
                                              onClick={editable?this.onHandleClickOnBalls:(e) => {}}
                                              highlight={(selected && selected.includes(number))}
                                              rightLast={i < 12 ?((i + 1) % 5 === 0):((i + 2) % 5 === 0)}
                                              bottomLast={i > 18}

                                        >
                                            <span>{number??" "}</span>
                                        </Ball>
                                    </React.Fragment>
                                )
                            })}
                        </BallContainer>
                        {(editable && ticketCreatingContext)?(
                            <ChooseNumberButton onClick={this.onHandleClickOnChooseButton}>
                                {applicationContext.translator("Choose your numbers")} {ticketCreatingContext.state.openNumberChooser === id?<Icon icon={"chevron-up"} />:<Icon icon={"chevron-down"} />}
                            </ChooseNumberButton>
                        ):(
                            dateOff?(null):<TicketDate>
                                {applicationContext.translator("Purchase date")}: {date??""}
                            </TicketDate>
                        )}

                    </Body>
                    <Toolbar>
                        {editable?(
                            <React.Fragment>
                                <IconButton onClick={this.doShuffle}>
                                    <Icon icon={"refresh"} rotate={true} />
                                </IconButton>
                                <IconButton onClick={this.doDelete}>
                                    <Icon icon={"trash"} />
                                </IconButton>
                            </React.Fragment>
                        ):(
                            <TicketID dateOff={dateOff}>{ticketId(id)}</TicketID>
                        )}

                    </Toolbar>
                </Wrapper>
                {ticketCreatingContext && ticketCreatingContext.state.openNumberChooser === id && <NumbersListContainer>
                    <NumbersListToolbar>
                        <ContainerWrapper>
                            <ToolbarButton onClick={this.doClearNumbers}>
                                <ToolbarButtonWrapper>
                                    <Icon icon={"eraser"} />
                                    <span>{applicationContext.translator("Clear numbers")}</span>
                                </ToolbarButtonWrapper>
                            </ToolbarButton>
                            <ToolbarButton onClick={this.doShuffleAnimation}>
                                <ToolbarButtonWrapper>
                                    <Icon icon={"shuffle"} />
                                    <span>{applicationContext.translator("Shuffle numbers")}</span>
                                </ToolbarButtonWrapper>
                            </ToolbarButton>
                        </ContainerWrapper>

                    </NumbersListToolbar>
                    <NumbersList>
                        {arrayRange(1, 75, 1).map((number, index) => {
                            return (<Ball editable={editable} list={true} active={this.state.numbers.includes(number)} onClick={(e) => this.onChooseNumber(number)}><span>{number}</span></Ball>);
                        })}
                    </NumbersList>
                    <ContainerWrapper>
                        <ToolbarButton right={true} onClick={this.onHandleClickOnBalls}>
                            <ToolbarButtonWrapper>
                                <span>{applicationContext.translator("Close")}</span>
                            </ToolbarButtonWrapper>
                        </ToolbarButton>
                    </ContainerWrapper>

                </NumbersListContainer>}
                {error && <ErrorText>{error}</ErrorText>}
            </Container>
        );
    }
}
Ticket.propTypes = {
    id: PropTypes.string,
    index: PropTypes.number,
    numbers: PropTypes.array,
    editable: PropTypes.bool,
    price: PropTypes.any,
    date: PropTypes.string,
    selected: PropTypes.array,
    error: PropTypes.bool,
    prize: PropTypes.string,
    reserve: PropTypes.number
}
export default withApplicationContext(withTicketCreatingContext(Ticket));