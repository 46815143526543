import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {Container} from 'react-bootstrap';
import SubHeaderBar from "../../components/ui/SubHeaderBar/SubHeaderBar";
import {withAlert} from "react-alert";
import {MenuTab, MenuTabItem, AccountContainer, Wrapper} from "./style";
import {NavLink} from "react-router-dom";
import {isRouteActive} from "../../helpers";
import {
    ROUTE_ACCOUNT_DEPOSIT, ROUTE_ACCOUNT_DEPOSITS_HISTORY,
    ROUTE_ACCOUNT_MY_TICKETS,
    ROUTE_ACCOUNT_PLAYED_HISTORY,
    ROUTE_ACCOUNT_PROFILE
} from "../../routes/routes";
import PropTypes from "prop-types";
import Loading from "../../components/ui/Loading";
import BaseMaster from "../BaseMaster";
import {withAccountContext} from "../../contexts/AccountContext";

class MyAccount extends Component {
    render() {
        const {applicationContext, children, loading} = this.props;
        return (
            <React.Fragment>
                <SubHeaderBar />
                <Container style={{ paddingTop: '6em' }}>
                    <Wrapper vertical={false}>
                        <MenuTab className={"menu-tabs"}>
                            <MenuTabItem active={isRouteActive(ROUTE_ACCOUNT_PROFILE)}><NavLink to={ROUTE_ACCOUNT_PROFILE}>{applicationContext.translator("Profile")}</NavLink></MenuTabItem>
                            <MenuTabItem active={isRouteActive(ROUTE_ACCOUNT_MY_TICKETS)}><NavLink to={ROUTE_ACCOUNT_MY_TICKETS}>{applicationContext.translator("My Tickets")}</NavLink></MenuTabItem>
                            <MenuTabItem active={isRouteActive(ROUTE_ACCOUNT_PLAYED_HISTORY)}><NavLink to={ROUTE_ACCOUNT_PLAYED_HISTORY}>{applicationContext.translator("My Played History")}</NavLink></MenuTabItem>
                            <MenuTabItem active={isRouteActive(ROUTE_ACCOUNT_DEPOSIT)}><NavLink to={ROUTE_ACCOUNT_DEPOSIT}>{applicationContext.translator("Deposit")}</NavLink></MenuTabItem>
                            <MenuTabItem active={isRouteActive(ROUTE_ACCOUNT_DEPOSITS_HISTORY)}><NavLink to={ROUTE_ACCOUNT_DEPOSITS_HISTORY}>{applicationContext.translator("Deposits History")}</NavLink></MenuTabItem>
                        </MenuTab>
                        <AccountContainer className={"account-container"}>
                            {loading?(<Loading animation={true} transparent={true}/>):children}
                        </AccountContainer>
                    </Wrapper>
                </Container>
            </React.Fragment>
        );
    }
}
MyAccount.propTypes = {
    loading: PropTypes.bool
}
export default withAlert()(withApplicationContext(withAccountContext(MyAccount)));