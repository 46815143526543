import styled from "styled-components";
import {Col} from 'react-bootstrap';
export const SliderContainer = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
  position: relative;
  background-image: url("/assets/images/slider.jpg");
  background-color: #f7f7f7;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;
export const SectionContainer = styled.div`
  width: 100%;
  padding: 3em 0 3em 0;
  ${(props) => props.bg && `background: ${props.bg};`}
  ${(props) => props.special && `
    background: #fff;
    color: #333;
  `}
  &:last-child{
    padding-bottom: 5em;
  }
  
`;
export const ResultContainer = styled.div`
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
`
export const DrawResultTitle = styled.h5`
  font-size: 2.5em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.3em;
  @media screen and (max-width: 700px) {
    text-align: center;
    font-size: 2em;
  }
  & span{
    display: block;
    font-size: 0.8em;
    margin-bottom: 4px;
  }
`;
export const PrizesContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 2em;
  color: #333;
`;
export const StepsContainer = styled.div`
  width: 100%;
  
`;

export const CenterCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Title = styled.h3`
  font-size: 1.4em;
  font-weight: 600;
  padding: 0.5em 0;
  background: var(--color-primary);
  color: #fff;
  border-radius: 10px;
`
export const PrizeTitle = styled.h3`
  text-align: center;
  background: #ffffff;
  color: #333;
  padding: 0.5em 0;
  border-radius: 10px;
`
export const StepsTitle = styled.h3`
  color: var(--color-third);
  font-size: 1.8em;
  font-weight: 700;
  @media screen and (max-width: 700px) {
    text-align: center;
  }
`

export const PrizeList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`
export const PrizeListItem = styled.li`
  //border-bottom: 1px solid var(--color-secondary-on);
  &:last-child{
    border-bottom: 0;
  }
  display: flex;
  align-items: center;
  padding: 0.3em 0;
  @media screen and (max-width: 700px) {
    display: block;
  }
  & span{
    width: 50%;
    @media screen and (max-width: 700px) {
      width: 100%;
      display: block;
      text-align: center;
      margin: .5em 0;
    }
    ${(props) => (props.winner)?`
        font-size: 1.5em;
       
    `:`
        font-size: 1.3em;
    `}
    font-weight: 600;
    &:last-child{
      ${(props) => (props.winner)?`
        font-size: 2em;
      `:`
        font-size: 1.6em;
      `}
    }
  };
  ${(props) => (props.winner)?`color: #000;`:`color: var(--color-secondary-on);`}
`
export const StepsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 700px) {
    display: block;
  }
`
export const StepItem = styled.li`
  display: flex;
  align-items: center;
  ${(props) => (props.full)?`width: 66%;`:`width: 33%;`}
  margin-top: 1em;
  @media screen and (max-width: 700px) {
    display: block;
    width: 100%;
    text-align: center;
  }
`
export const StepNumber = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-secondary);
  color: #fff;
  font-size: 2em;
  font-weight: 700;
  border-radius: 100%;
  @media screen and (max-width: 700px) {
    margin: 1em auto;
  }
`
export const StepDescription = styled.div`
  margin: 1em;
  font-size: 1.1em;
  font-weight: 600;
`
export const StepSubDescription = styled.div`
 color: var(--color-primary);
`


export const PrizeImage = styled.img`
  width: 100%;
  margin: 1em 0;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  box-shadow: 0px 0px 6px #ccc;
`;