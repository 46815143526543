import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const DrawResult = styled.div`
  color: var(--color-text);
  max-width: 700px;
  margin: 1em auto;
  background: var(--color-headerbar-background);
  padding: 1em;
  border-radius: 10px;
`
export const DrawResultItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
  &:first-child{
    margin-top: 0;  
  }
  & span{
    background: #1b9119;
    ${(props) => !props.bingo?`
      width: 30px;
      height: 30px;
      border-radius: 100%;
    `:`
      border-radius: 10px;
      padding: .1em .5em;
    `}
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 600; 
  }
`

export const Numbers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  flex-wrap: wrap;
`
export const Number = styled.div`
  width: 32px;
  max-width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #333;
  font-weight: 600;
  border-radius: 100%;
  margin: 0.3em;
  flex: 1 0 32px;
  ${(props) => props.fill && `
    background: #1b9119;
    color: #fff;
  `}
`