import React, {Component} from 'react';
import {Route, Routes} from "react-router-dom";
import {withApplicationContext} from "../contexts/ApplicationContext"
import {
    ROUTE_ACCOUNT, ROUTE_ACCOUNT_DEPOSIT, ROUTE_ACCOUNT_DEPOSITS_HISTORY,
    ROUTE_ACCOUNT_MY_TICKETS, ROUTE_ACCOUNT_PLAYED_HISTORY,
    ROUTE_ACCOUNT_PROFILE,
    ROUTE_ACCOUNT_PROFILE_EMAIL_UPDATE,
    ROUTE_ACCOUNT_PROFILE_MOBILE_UPDATE
} from "./routes";
import Profile from "../scenes/MyAccount/Profile/Profile";
import UpdateEmail from "../scenes/MyAccount/Profile/UpdateEmail/UpdateEmail";
import UpdateMobile from "../scenes/MyAccount/Profile/UpdateMobile/UpdateMobile";
import MyTickets from "../scenes/MyAccount/MyTickets/MyTickets";
import PlayedHistory from "../scenes/MyAccount/PlayedHistory/PlayedHistory";
import Deposit from "../scenes/MyAccount/Deposit/Deposit";
import DepositsHistory from "../scenes/MyAccount/DepositsHistory/DepositsHistory";
import Home from "../scenes/Home/Home";
import BaseMaster from "../scenes/BaseMaster";

class AccountRouter extends Component {
    render() {
        return (
            <Routes>
                <Route  path={ROUTE_ACCOUNT_PROFILE} element={<BaseMaster><Profile /></BaseMaster>} />
                <Route  path={ROUTE_ACCOUNT_PROFILE_EMAIL_UPDATE} element={<BaseMaster><UpdateEmail /></BaseMaster>} />
                <Route  path={ROUTE_ACCOUNT_PROFILE_MOBILE_UPDATE} element={<BaseMaster><UpdateMobile /></BaseMaster>} />
                <Route  path={ROUTE_ACCOUNT_MY_TICKETS} element={<BaseMaster><MyTickets /></BaseMaster>} />
                <Route  path={ROUTE_ACCOUNT_PLAYED_HISTORY} element={<BaseMaster><PlayedHistory /></BaseMaster>} />
                <Route  path={ROUTE_ACCOUNT_DEPOSIT} element={<BaseMaster><Deposit /></BaseMaster>} />
                <Route  path={ROUTE_ACCOUNT_DEPOSITS_HISTORY} element={<BaseMaster><DepositsHistory /></BaseMaster>} />
            </Routes>
        );
    }
}

export default withApplicationContext((AccountRouter));