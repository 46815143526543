import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const Wrapper = styled.div`
  ${(props) => props.vertical && `
        display: flex;
        & .menu-tabs{
            width: 30%;
            max-width: 300px;
            display: block;
            & li{
                margin: .5em 0;
            }
        }
        & .account-container{
            width: 100%;
            padding: 2em;
            border: 1px solid #ddd;
            margin: 0.5em 1em;
            border-radius: 5px;
        }
  `}
`
export const AccountContainer = styled.div`
  padding: 1em 1em;
  margin: 2em 0em;
  border-radius: 5px;
  & hr{
    margin: 3em 0;
  }
`;
export const MenuTab = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 700px) {
    display: block;
  }
`
export const MenuTabItem = styled.li`
  width: 100%;
  margin: 0 0.1em;
  @media screen and (max-width: 700px) {
    margin: 0.5em 0.1em;
  }
  & a{
    background: var(--color-third);
    display: block;
    padding: 1em;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    ${(props) => props.active && `
       background: var(--color-secondary);
       color: #fff;
        transition: all ease-in-out .2s;
    `}
    &:hover{
      background: var(--color-secondary);
      color: #fff;
      transition: all ease-in-out .2s;
    }
  }
  
`