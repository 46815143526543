import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {
    SectionContainer,
    PrizesContainer,
    CenterCol,
    PrizeTitle,
    PrizeList, PrizeListItem,
    PrizeImage
} from "./style.jsx";
import {Container, Row, Col, NavLink} from 'react-bootstrap';
import HorizontalBalls from "../../components/ui/Ball/HorizontalBalls";
import JackpotPrice from "../../components/ui/JackpotPrice/JackpotPrice";
import NationalIDBox from "../../components/ui/NationalIDBox";
import Winner from "../../components/ui/Winner";
import PersianDate from "../../components/ui/PersianDate";

class Prizes extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SectionContainer bg={"#f1f1f1"}>
                <Container>
                    <PrizesContainer>
                        <Row>
                            <Col md={4}>
                                <PrizeTitle>{applicationContext.translator("Round 1 winner")}</PrizeTitle>
                                <PrizeImage src={"/assets/images/bicycle.png"}/>
                            </Col>
                            <Col md={4}>
                                <PrizeTitle>{applicationContext.translator("Round 2 winner")}</PrizeTitle>
                                <PrizeImage src={"/assets/images/iphone.png"}/>
                            </Col>
                            <Col md={4}>
                                <PrizeTitle>{applicationContext.translator("Round 3 winner")}</PrizeTitle>
                                <PrizeImage src={"/assets/images/3000$.png"}/>
                            </Col>
                        </Row>
                    </PrizesContainer>
                </Container>
            </SectionContainer>

        );
    }
}

export default withApplicationContext(Prizes);