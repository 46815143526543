import styled from "styled-components";

export const FooterMenuContainer = styled.div`
  width: 100%;
  background: var(--color-third);
  color: #fff;
  border-top: 2px solid #2e2e2e;
  border-bottom: 2px solid #2e2e2e;
  margin-top: 0;
  padding: 2em;
`;
export const FooterMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 700px) {
    display: block;
    text-align: center;
  }
`
export const FooterMenuItem = styled.li`
  padding: 1em 2em;
  & a{
    color: #fff;
    font-weight: 600;
    &:hover{
      color: var(--color-primary);
      transition: all ease-in-out 0.2s;
    }
  }
`
export const FooterCopyrightContainer = styled.div`
  width: 100%;
  background: var(--color-third);
  color: #fff;
  padding: 2em;
  text-align: center;

`
export const ConditionText = styled.p`
  font-size: 1em;
  font-weight: 600;
  line-height: 1.7em;
  border-bottom: 2px solid #2e2e2e;
  margin-top: 1em;
  padding-bottom: 3em;
  & a{
    color: #fff;
    display: block;
    margin-top: .5em;
    &:hover{
      color: var(--color-primary);
      transition: all ease-in-out .3s;
    }
  }
`
export const CopyrightText = styled.p`
  font-size: 0.8em;
  line-height: 1.7em;
  direction: ltr;

`
export const SocialIcons = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`
export const SocialIcon = styled.li`
  & a{
    color: #fff;
    font-size: 24px;
    &:hover{
      color: var(--color-primary);
      transition: all ease-in-out .3s;
    }
  }
`



