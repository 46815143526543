import styled from "styled-components";
import {Col, Form, Button} from "react-bootstrap";
export const TitleBar = styled.div`
    width: 100%;
    color: var(--color-primary);
`;
export const Title = styled.h3`
    color: var(--color-secondary);
    margin-bottom: 1.5em;
`;
export const TicketContainer = styled.div`
  width: 100%;
`
export const TicketSection = styled.div`
  transition: all .3s;
  width: calc(33.3333% - 2em);
  margin: 0 1em;
  position: relative;
  @media screen and (max-width: 1400px) {
    width: calc(50% - 4em);
  }
  @media screen and (max-width: 992px) {
    width: calc(100% - 2em);
  }
`;
export const TicketsContainer = styled.div`
    display: flex;
  flex-wrap: wrap;
`
export const AddNewTicket = styled.div`
  width: 100%;
  background: #f3f3f3;
  border-radius: 10px;
  margin: 1em auto;
  align-content: center;
  color: #333;
  position: relative;
  min-height: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  &:hover{
    background: #fff;
    box-shadow: 0 5px 15px 0 rgb(0 0 0 / 15%);
    transition: all ease-in-out 0.3s;
  }
`
export const AddNewTicketWrapper = styled.div`
  padding: 1em;
  text-align: center;
  font-size: .9em;
  line-height: 1.7em;
  font-weight: 600;;
  & div{
    display: block;
    margin-bottom: 1em;
  }
`
export const AddNewTicketButton = styled(Button)`

  margin-top: 1em
`
export const CheckoutContainer = styled.div`
  width: 100%;
  background: var(--color-secondary);
  margin: 1em auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 15%);
  max-width: 350px;
  color: #fff;
`;
export const CheckoutWrapper = styled.div`
  padding: 2em;
`;
export const CheckoutTitle = styled.h5`
  color: #202020;
  text-align: center;
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: .5em;
`;
export const CheckoutSubTitle = styled.h6`
  color: #fff;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.7em;
  text-align: center;
  margin-bottom: 1.5em;
`;
export const MultiDrawOption = styled(Form.Check)`
  font-size: 1em;
  font-weight: 500;
  margin-top: .7em;
  & .form-check-input{
    float: right;
    margin-left: 0.7em;
  }
  & .form-check-input:checked{
    background-color: #333;
    border-color: #333; 
  }
  & .form-check-input:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
  }
`
export const Line = styled.hr`

`
export const CartContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
export const CartItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
`
export const CartItemLabel = styled.div`
  & b{
    display: block;
  }
`
export const CartItemPrice = styled.div`
  direction: ltr;
  font-weight: 600;
`

