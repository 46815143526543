import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import withAuth from "../../../contexts/withAuth";
import LoggedIn from "../LoggedIn";
import withAdminAuth from "../../../contexts/withAdminAuth";
import AccountService from "../../../services/AccountService";
import AdminService from "../../../services/AdminService";
import {accountId, renderAccountID, ticketId} from "../../../helpers";
import DataList from "../../../components/ui/DataList/DataList";
import HorizontalBalls from "../../../components/ui/Ball/HorizontalBalls";
import {Ball, BallContainer} from "../../../components/ui/Ticket/style";
import Search from "./Search";

class PlayerTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            search: {

            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
        }

    }
    componentDidMount = async () => {
        await this.fetchData(1);

        this.setState({ loading: false })
    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        this.setState({ loadingData: true })
        let data = await AdminService.tickets({
            page,
            limit: 10,
            sort_field,
            sort_dir,
            ...(search && search.searchTrim())
        });
        this.setState({
            loadingData: false,
            data: data,
            sort:{
                key: sort_field,
                dir: sort_dir
            }
        }, callback)
    }
    renderSearch = () => {
        return <Search data={this.state.search} doFetchData={this.fetchData}/>;
    }
    onUpdateSearch = (data) => {
        this.setState({
            search: data
        })
    }
    renderNumber = (value, index, row) => {
        return (
            <BallContainer margin={"0"}>
                {value.map((number, i) => {
                    let highlight = (row.draw && row.draw.won_number)?(row.draw.won_number.includes(number.toString())):false;
                    let reserve = (row.draw && row.draw['reserve_number'])?(row.draw['reserve_number'] === number):false;
                    return (
                        <React.Fragment>
                            {i === 12 &&
                                <Ball bingo={true}>
                                    <span>BINGO</span>
                                </Ball>
                            }
                            <Ball editable={false}
                                  fill={true}
                                  highlight={highlight}
                                  reserve={row.prize === 6 && reserve}
                            >
                                <span>{number}</span>
                            </Ball>
                        </React.Fragment>
                    )
                })}
            </BallContainer>
        );
    }
    renderPrize = (prize, index, row) => {
        const {applicationContext} = this.props;
        return applicationContext.translator(prize);
    }

    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number"},
                        {key: "id", label: "Ticket Id", render: ticketId, dir: "ltr"},
                        {key: "user_id", label: "Account ID", render: renderAccountID, dir: "ltr"},
                        {key: 'draw_date', label: "Draw date", format: "date"},
                        {key: "numbers", label: "Numbers", render: this.renderNumber, dir: "ltr"},
                        {key: "prize", label: "Prize", render: this.renderPrize},
                        {key: "created_at", label: "Purchase date", format: "date"},
                        {key: "created_at", label: "Purchase time", format: "time"}
                    ]}
                    data={this.state.data}
                    action={this.fetchData}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    loading={this.state.loadingData}
                />
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext(withAdminAuth(PlayerTickets)))));