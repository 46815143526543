import styled from "styled-components";

export const Container = styled.div`
  margin: 1em 0;
  position: relative;
`;
export const NumbersList = styled.div`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  direction: ltr;
`
export const Ball = styled.div`
    flex: 1 0 6.25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 700;
    height: 42px;
    line-height: 1;
    position: relative;
    width: 6.25%;
    color: var(--play-card-text);
    border: 1px solid var(--play-card-border);
    background: var(--play-card-background);
    ${(props) => props.disabled?`cursor: initial;`:`cursor: pointer;`}
    ${(props) => props.active?`
      background: var(--play-card-active-background);
      color: var(--play-card-active-text);
      box-shadow: none;
      cursor: initial;
    `:!props.disabled && `
        &:hover{
          color: var(--play-card-head-text);
          background: var(--play-card-head-background);
          box-shadow: none;
          transition: all ease-in-out .3s;
        }
    `}
    ${(props) => props.highlight?`
      background: var(--play-card-highlight-background);
      color: var(--play-card-highlight-text);
      box-shadow: none;
      cursor: initial;
    `:!props.disabled && `
        &:hover{
          color: var(--play-card-head-text);
          background: var(--play-card-head-background);
          box-shadow: none;
          transition: all ease-in-out .3s;
        }
    `}
    
    
    ${(props) => props.bingo && `
        background: var(--play-card-head-background);
        color: var(--play-card-head-text);
        cursor: initial;
    `}
    


`;
export const Title = styled.div`
  width: fit-content;
  background: var(--play-card-head-background);
  color: var(--play-card-head-text);
  text-align: center;
  padding: 0.5em 1em;
  margin: 0 auto;
  font-size: .9em;
  font-weight: 600;
`