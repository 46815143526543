import React, {Component} from 'react';
import {HeaderContainer, Items, Item} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class SubHeaderBar extends Component {

    render() {
        const {name, margin, applicationContext} = this.props;

        return (
            null
        );
    }
}
SubHeaderBar.propTypes = {

}
export default withApplicationContext(SubHeaderBar);