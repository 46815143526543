import React, {Component} from 'react';
import {Container, NumbersList, Ball, Title} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {arrayRange} from "../../../helpers";
class TicketChooser extends Component {

    render() {
        const {selected, onClick, highlight, title, disabled} = this.props;

        return (
            <Container>
                {title && <Title>{title}</Title>}
                <NumbersList>
                {arrayRange(1, 75, 1).map((number) => {
                    return <React.Fragment>
                        {(number === 1) && <Ball bingo={true}><span>B</span></Ball>}
                        <Ball list={true}
                              disabled={disabled}
                              active={selected && selected.includes(number)}
                              highlight={highlight && highlight.includes(number)}
                              onClick={(e) => onClick && onClick(number)}><span>{number}</span></Ball>

                        {(number === 15) && <Ball bingo={true}><span>I</span></Ball>}
                        {(number === 30) && <Ball bingo={true}><span>N</span></Ball>}
                        {(number === 45) && <Ball bingo={true}><span>G</span></Ball>}
                        {(number === 60) && <Ball bingo={true}><span>O</span></Ball>}
                    </React.Fragment>
                })}
                </NumbersList>
            </Container>
        );
    }
}
TicketChooser.propTypes = {
    selected: PropTypes.array,
    highlight: PropTypes.array,
    onClick: PropTypes.func,
    title: PropTypes.string,
    disabled: PropTypes.bool
}
export default withApplicationContext(TicketChooser);