import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {
    SliderContainer
} from "./style.jsx";
import {Container, Row, Col, NavLink} from 'react-bootstrap';

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }


    componentDidMount() {

    }

    render() {
        const {applicationContext} = this.props;
        return (
            <SliderContainer>
                <Container>
                    {/*<img src={`/assets/images/slider.jpg`} width={"100%"}/>*/}
                </Container>
            </SliderContainer>

        );
    }
}

export default withApplicationContext(Slider);