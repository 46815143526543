import React, {Component} from 'react';
import AppHeader from "../components/ui/AppHeader/AppHeader";
import Footer from "../components/ui/Footer/Footer";
import {withApplicationContext} from "../contexts/ApplicationContext";
import {AccountProvider} from "../contexts/AccountContext";
import Loading from "../components/ui/Loading";
import StorageService from "../services/StorageService";
import AccountService from "../services/AccountService";
import TokenService from "../services/TokenService";

class BaseMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loggedIn: false,
            userInfo: {}
        }

    }

    doChangeLanguage = async () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        if (urlSearchParams.has('locale') && ['fa', 'en'].includes(urlSearchParams.get('locale'))) {
            StorageService.set('locale', urlSearchParams.get('locale'));
        }
        if (StorageService.get('locale')) {
            return StorageService.get('locale');
        }
        return process.env.REACT_APP_DEFAULT_LOCALE;
    }

    componentDidMount = async () => {
       const {applicationContext} = this.props;
        let userInfo = await this.fetchUserInfo();
        let locale = await this.doChangeLanguage();
        applicationContext.doUpdateLocale(locale, () => {
            this.setState({
                userInfo,
                loggedIn: (userInfo)?true:false,
                loading: false
            })
        })

    }

    fetchUserInfo = async () => {
        let userInfo = null;
        if(StorageService.get('token')){
            userInfo = await AccountService.getUserBoard().then((data) => {
                return data;
            })
        }
        return userInfo;
    }
    updateUserInfo = (data, callback, loggedIn = true) => {
        this.setState({
            loggedIn: loggedIn,
            userInfo: data
        }, callback)
    }

    handleLogout = async (callback) => {
        TokenService.removeUser();
        this.setState({
            loggedIn: false,
            userInfo: {},
        }, callback)
    }
    render() {
        const {children} = this.props;
        return (
            <AccountProvider value={{
                state: this.state,
                updateUserInfo: this.updateUserInfo,
                handleLogout: this.handleLogout,
            }}>
                {this.state.loading?(
                    <Loading animation={true} />
                ):(
                    <React.Fragment>
                        <AppHeader />
                        <div style={{ minHeight: '400px' }}>{children}</div>
                        <Footer />
                    </React.Fragment>
                )}
            </AccountProvider>
        );
    }
}

export default withApplicationContext(BaseMaster);